import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import MainLayout from '../layouts/MainLayout'

const ViewParticipants = () => {
  console.log('ViewParticipants')
  return (
    <>
      <MainLayout>
        <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={10} sm={9} md={5} lg={5}>
            <Typography>
              Las siguientes personas tienen autorización para asistir a charlas:
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Calidad:
            </Typography>
            <Typography>
              Mantenimiento:
            </Typography>
            <Typography>
              Seguridad Patrimonial:
            </Typography>
            <Typography>
              SASS:
            </Typography>
          </Grid>
        </Grid>
      </MainLayout>
    </>
  )
}

export default ViewParticipants