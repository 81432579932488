import * as React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { GeolocatedProps, geolocated } from 'react-geolocated'
import Grid from '@mui/material/Grid'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Link } from 'react-router-dom'
import CreateIcon from '@mui/icons-material/Create'
import PostAddIcon from '@mui/icons-material/PostAdd'
import PeopleIcon from '@mui/icons-material/People'
import PageviewIcon from '@mui/icons-material/Pageview'
import MainLayout from '../layouts/MainLayout'
// import IconButton from '@mui/material/IconButton'
// import MenuIcon from '@mui/icons-material/Menu'

// const [location, setLocation] = React.useState({})

// export const getGeoLocation = async () => {
//   const result: any = await new Promise((res, rej) => {
//     navigator.geolocation.getCurrentPosition(res, rej)
//   })
//   const cordData = {
//     data: {
//       latitude: result.coords.latitude,
//       longitude: result.coords.longitude,
//     },
//   }
//   return cordData
// }

const MainScreen = (props: GeolocatedProps) => {
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = props

  console.log(`isGeolocationAvailable : ${isGeolocationAvailable}, isGeolocationEnabled : ${isGeolocationEnabled}, coords : ${coords} `)

  console.log(coords)

  return (
    <>
      <MainLayout>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={5}>
            <Typography sx={{ mt: 5 }}>
              Latitud:
              {coords && coords.latitude}
            </Typography>
            <Typography>
              Longitud:
              {coords && coords.longitude}
            </Typography>
            <Typography
              variant="h5"
              // gutterBottom
              sx={{ mt: 3, textAlign: 'center' }}
            >
              Menu
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ mt: 1, textAlign: 'center' }}
            >
              Por favor seleccione una opción
            </Typography>
            <ButtonGroup
              sx={{ display: 'flex', margin: '20px auto' }}
              orientation="vertical"
              aria-label="vertical outlined button group"
            >
              <Button
                sx={{ p: 2 }}
                key="one"
                startIcon={<PostAddIcon />}
              >
                <Link
                  to="/create"
                  style={{ textDecoration: 'none' }}
                >
                  Crear verificación de asistencia
                </Link>
              </Button>
              <Button
                sx={{ p: 2 }}
                key="one"
                startIcon={<PageviewIcon />}
              >
                <Link
                  to="/attendances"
                  style={{ textDecoration: 'none' }}
                >
                  Ver las verificaciones de asistencia
                </Link>
              </Button>
              <Button
                sx={{ p: 2 }}
                key="two"
                startIcon={<CreateIcon />}
              >
                <Link
                  to="/edit"
                  style={{ textDecoration: 'none' }}
                >
                  Editar verificaciones de asistencia actuales
                </Link>
              </Button>
              <Button
                sx={{ p: 2 }}
                key="three"
                startIcon={<PeopleIcon />}
              >
                <Link
                  to="/participants"
                  style={{ textDecoration: 'none' }}
                >
                  Ver asistentes
                </Link>
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </MainLayout>
    </>
  )
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
  isOptimisticGeolocationEnabled: true,
})(MainScreen)