import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import MainLayout from '../layouts/MainLayout'

const ViewAttendance = () => {
  console.log('ViewAttendance')
  return (
    <>
      <MainLayout>
        <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={10} sm={9} md={5} lg={5}>
            {/* <Typography>
              Usted está viendo la verificación de asistencia n° 1
            </Typography> */}
            <Typography sx={{ mt: 2 }}>
              Identificador de la verificación de asistencia: 1
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Fecha: 01-02-2022
            </Typography>
            <Typography sx={{ mt: 2 }}>
              El personal seleccionado es:
            </Typography>
            <Typography>
              Calidad
            </Typography>
          </Grid>
        </Grid>
      </MainLayout>
    </>
  )
}

export default ViewAttendance