import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'

const EditAttendances = () => {
  console.log('create attendance')
  return (
    <>
      <MainLayout>
        <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={10} sm={9} md={5} lg={5}>
            <Typography>
              Editar verificaciones de asistencia
            </Typography>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 2 }}>
                <Link
                  to="/edit/1"
                  style={{ textDecoration: 'none' }}
                >
                  Verificación de asistencia 1
                </Link>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <Link
                  to="/attendance/1"
                  style={{ textDecoration: 'none' }}
                >
                  Verificación de asistencia 2
                </Link>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <Link
                  to="/attendance/1"
                  style={{ textDecoration: 'none' }}
                >
                  Verificación de asistencia 3
                </Link>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <Link
                  to="/attendance/1"
                  style={{ textDecoration: 'none' }}
                >
                  Verificación de asistencia 4
                </Link>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <Link
                  to="/attendance/1"
                  style={{ textDecoration: 'none' }}
                >
                  Verificación de asistencia 5
                </Link>
              </Typography>
            </Grid>
            <Box component="form" noValidate sx={{ mt: 1 }} />
          </Grid>
        </Grid>
      </MainLayout>
    </>
  )
}

export default EditAttendances