import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import { Link } from 'react-router-dom'
import { Children } from '../../types'

const MainLayout = ({ children }: Children) => {
  console.log('main layout')

  return (
    <>
      <div>
        <CssBaseline />
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <Link
                  to="/start"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  Bienvenido Usuario
                </Link>
              </Typography>
              <Button color="inherit">
                <Link
                  to="/"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  Cerrar sesión
                </Link>
              </Button>
            </Toolbar>
          </AppBar>
          {children}
        </Box>
      </div>
    </>
  )
}

export default MainLayout