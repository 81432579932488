import styled from '@emotion/styled'
import CssBaseline from '@mui/material/CssBaseline'
// import WAVES from '../../../node_modules/vanta/dist/vanta.waves.min'
import techBg from '../../assets/Futuristic.jpg'
import { Children } from '../../types'

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

// const Bg = styled.div`

// `

const AuthLayout = ({ children }: Children) => {
  console.log('Auth layout log')
  return (
    <>
      <div style={{ height: '100vh', backgroundImage: `url(${techBg})`, backgroundSize: 'cover' }}>
        <CssBaseline />
        <Root>
          {children}
        </Root>
      </div>
    </>
  )
}

export default AuthLayout