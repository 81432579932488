import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import Signin from '../components/auth/SigninScreen'
import CreateAttendance from '../components/main/CreateAttendance'
import EditAttendance from '../components/main/EditAttendance'
import EditAttendances from '../components/main/EditAttendances'
import MainScreen from '../components/main/MainScreen'
import ViewAttendance from '../components/main/ViewAttendance'
import ViewAttendances from '../components/main/ViewAttendances'
import ViewParticipants from '../components/main/ViewParticipants'

const Routes = () => (
  <>
    <Router>
      <Switch>
        <Route path="/edit/:id">
          <EditAttendance />
        </Route>
        <Route path="/edit">
          <EditAttendances />
        </Route>
        <Route path="/participants">
          <ViewParticipants />
        </Route>
        <Route path="/attendance/:id">
          <ViewAttendance />
        </Route>
        <Route path="/attendances">
          <ViewAttendances />
        </Route>
        <Route path="/create">
          <CreateAttendance />
        </Route>
        <Route path="/start">
          <MainScreen />
        </Route>
        <Route path="/">
          <Signin />
        </Route>
      </Switch>
    </Router>
  </>
)

export default Routes