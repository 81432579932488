import TextField from '@mui/material/TextField'
// import Alert from '@mui/material/Alert'
import { useFormik } from 'formik'
// import * as Yup from 'yup'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

const SigninForm = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Por favor ingrese un email válido')
      // .min(8, 'Must be at least 8 characters')
      .max(255)
      .required('Por favor ingrese un email'),
    password: Yup.string()
      // .min(6, ' Mus be a last 6 characters ')
      .max(255)
      .required('Por favor ingrese una contraseña'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2))
      console.log('Ha iniciado sesión', JSON.stringify(values, null, 2))
    },
  })

  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/start')
  }

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ my: 2 }}
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            sx={{ my: 2 }}
            fullWidth
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button color="primary" variant="contained" fullWidth type="submit" onClick={redirectToDashboard}>
            Iniciar sesión
          </Button>
          <Button
            // component={Link}
            // to="/auth/reset-password"
            sx={{ mt: 4 }}
            fullWidth
            color="primary"
            variant="outlined"

          >
            Olvidó su contraseña?
          </Button>
        </form>
      </div>

    </>
  )
}

export default SigninForm