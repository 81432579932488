import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import logo from '../../assets/LogoHseqtechcl.png'
import SigninForm from './SigninForm'
import AuthLayout from '../layouts/AuthLayout'

const Brand = () => (
  <img
    style={{
      // width: '300px',
      // height: '150px',
      height: '110px',
      display: 'block',
      margin: '0 auto 0 auto',
    }}
    src={logo}
    alt="Pepsico"
  />
)

const Signin = () => (
  <>
    <AuthLayout>
      <Paper sx={{ padding: (theme) => theme.spacing(6) }}>
        <Brand />
        <Typography sx={{ mt: 2 }} color="primary" component="h1" variant="h4" align="center">
          Bienvenido/a!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Por favor inicie sesión para continuar
        </Typography>
        <SigninForm />
      </Paper>
    </AuthLayout>
  </>
)

export default Signin