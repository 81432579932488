import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'

import MainLayout from '../layouts/MainLayout'

const EditAttendance = () => {
  console.log('edit attendance')
  return (
    <>
      <MainLayout>
        <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={10} sm={9} md={5} lg={5}>
            <Typography>
              Editar verificación de asistencia
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Identificador de la asistencia"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Fecha"
                type="text"
                id="password"
                autoComplete="current-password"
              />
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Agregar a personal de Calidad" />
                <FormControlLabel control={<Checkbox />} label="Agregar a personal de Mantenimiento" />
                <FormControlLabel control={<Checkbox />} label="Agregar a personal de Seguridad Patrimonial" />
                <FormControlLabel control={<Checkbox />} label="Agregar a personal de SASS" />

              </FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    value="remember"
                    color="primary"
                  />
                )}
                label="Notificar por email al personal seleccionado"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Almacenar cambios
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MainLayout>
    </>
  )
}

export default EditAttendance